<template>
  <validationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(orderData)" class="curva-form curva-form-up iq-style4 mb-5">
      <b-row class="justify-content-center">
        <b-col lg="6" class="pt-3">
          <b-row>
            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.name') + ' (min 8 characters)'"
                          v-model="purchaseDetails.name"
                          :validate="'required|min:8'" :name="`Name`"/>
            </b-col>

            <b-col lg="12" class="mb-1">
              <main-select :placeholder="$t('forms.state')"
                           v-model="address.state_id"
                           :options="allStates"
                           label="name"
                           :reduce="item=>item.id"
                           :validate="'required'"
                           name="state"
                           class="curva-main-select"
              ></main-select>
            </b-col>

            <b-col lg="12" class="mb-1">
              <main-select :placeholder="$t('forms.city')"
                           v-model="address.city_id"
                           :options="allCities" label="name"
                           :reduce="item=>item.id"
                           :name="`city`"
                           :validate="'required'"
                           class="curva-main-select"
              ></main-select>
            </b-col>

            <b-col lg="12" class="mb-1">
              <main-select :placeholder="$t('forms.region')"
                           v-model="address.region_id"
                           :options="allRegions" label="name"
                           :reduce="item=>item.id"
                           :validate="'required'" :name="`region`"
                           class="curva-main-select"
                           @selecting="$emit('selectRegion', $event)"
              ></main-select>
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.street')" v-model="address.street"
                          :validate="'required'" :name="`Address`"/>
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.block_no')"
                          v-model="address.block_no"
                          :validate="'required|numeric'" :name="`block_no`"/>
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.floor_no')"
                          v-model="address.floor_no"
                          :validate="'required|numeric'" :name="`floor_no`"/>
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.flat_no')"
                          v-model="address.flat_no"
                          :validate="'required|numeric'"
                          :name="`flat_no`"
              />
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.phone')" v-model="address.phone1"
                          :validate="'required|numeric|digits:11'" :name="`Mobile number`"/>
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.phone2')" v-model="address.phone2"
                          :validate="'required|numeric|digits:11'" :name="`Second mobile number`"/>
            </b-col>

            <b-col lg="12" class="mb-1">
              <b-input-group>
                <input-form :placeholder="$t('cart.coupon')" v-model="purchaseDetails.copoun" class="flex-grow-1"
                            custom-class="curva-control-input"/>
                <b-input-group-append>
                  <b-button class="input-btn">{{ $t('cart.verify') }}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-col lg="12">
              <b-form-group
                  label-for="Notes"
              >
                <ValidationProvider name="notes" ref="Notes" v-slot="{ errors }">
                  <b-form-textarea
                      :placeholder="$t('cart.notes')+' ..'"
                      rows="2" v-model="purchaseDetails.notes"
                      :class="['curva-textarea', errors.length > 0 ? ' is-invalid' : '']"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col lg="12" class="mb-1 d-flex gap-2">
              <b-form-checkbox class="custom-checkbox-color-checked" plain v-model="terms" color="warning"
                               name="color" >
                            <span class="text-gray font-size-16 mx-4">{{ $t('cart.terms1') }}
                              <router-link :to="{ name: 'terms' }" class="text-warning">{{ $t('cart.terms2') }}</router-link></span>
              </b-form-checkbox>
            </b-col>

            <b-col lg="12">
              <b-button class="curva-granola-btn small-rounded-btn" type="submit" block>
                {{ $t('cart.step3') }}</b-button>
            </b-col>

          </b-row>
        </b-col>
      </b-row>

    </b-form>
  </validationObserver>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProductsServices from '@/modules/all-products/services/products.services'
import mainServices from '@/services/main'

export default {
  data () {
    return {
      deliveryFees: 0,
      purchaseDetails: {
        notes: '',
        copoun: '',
        email: 'test@curva.com',
        birthday: '',
        gender: 'male',
        name: ''
      },
      address: {
        state_id: '',
        city_id: '',
        region_id: '',
        block_no: '',
        flat_no: '',
        floor_no: '',
        phone1: '',
        phone2: '',
        street: ''
      },
      terms: false,
      genderOptions: [
        {
          key: 'male',
          value: this.$t('male')
        },
        {
          key: 'female',
          value: this.$t('female')
        }
      ],
      allStates: [],
      allCities: [],
      allRegions: []
    }
  },
  methods: {
    orderData () {
      if (!this.terms) {
        // event.preventDefault()
        core.showSnackbar('error', this.$t('forms.terms'))
      } else {
        this.$emit('order', { ...this.address, ...this.purchaseDetails })
      }
    },
    getAllStates () {
      mainServices.getState().then(res => {
        this.allStates = res.data.data
      })
    },
    getAllCities (id) {
      mainServices.getCities(id).then(res => {
        this.allCities = res.data.data
      })
    },
    getAllRegion (id) {
      mainServices.getRegions(id).then(res => {
        this.allRegions = res.data.data
      })
    }
  },
  created () {
    this.getAllStates()
  },
  watch: {
    'address.state_id': function (newVal) {
      this.allCities = []
      this.allRegions = []
      this.getAllCities(newVal)
    },

    'address.city_id': function (newVal) {
      this.allRegions = []
      this.getAllRegion(newVal)
    }

  },
  mounted () {
    core.index()
  }
}
</script>

<style scoped>

</style>
