<template>
  <b-container class="pt-4">
    <b-row>
      <b-col lg="9" class="text-initial">
        <curva-title :title="$t('cart.cart')" />
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="offlineCart.length">
      <b-col lg="9" class="text-initial">
<!--        {{ cartItems }}-->
        <b-table v-if="step == 1" responsive show-empty
                 :items="cartItems" :fields="tableHeader" primary-key="id"
                 class="curva-table" tbody-tr-class="curva-table-row">
          <!--    Name with photo    -->
          <template #cell(productName)="data">
            <router-link v-if="data.item" :to="{name: 'productDetails', params: {id: data.item.id}}" class="d-flex align-items-center">
              <img :src="data.item.image" class="table-img mx-2">
              <span style="max-width: 200px; display:inline-block; text-wrap: wrap; white-space: wrap;">
                {{data.item.name.length > 20 ? data.item.name.substr(0,20) +'...' : data.item.name }}
              </span>
            </router-link>
          </template>
          <template #cell(size)="data">
            {{data.item.size.size.name}}
          </template>
          <template #cell(color)="data">
          <span class="products_color_inside d-inline-block"
                :style="{'background-color': data.item.color }"></span>
          </template>
          <template #cell(unitPrice)="data">
            {{data.item.price}}
            <span class="currency">{{$t('egp')}}</span>
          </template>
          <template #cell(quantity)="data">
            <b-button class="table-quantity-btn"
                      @click="changeQuantity(data.item.barcode, data.item.quantity -1)"
                      :disabled="data.item.quantity < 2">-
            </b-button>
            <input class="table-quantity-text" v-model="data.item.quantity" disabled/>
            <b-button class="table-quantity-btn" :disabled="data.item.quantity === data.item.maxQuantity"
                      @click="changeQuantity(data.item.barcode, data.item.quantity +1)">
              +</b-button>
          </template>
          <template #cell(subtotal)="data">
            {{ data.item.quantity * data.item.price }}
            <span class="currency">{{$t('egp')}}</span>
          </template>
          <template #cell(action)="data">
            <button class="btn remove-btn" @click="deleteFromCart(data.item.barcode)"><i
                class="las la-trash font-size-18 mr-2"></i>
              {{ $t('cart.remove') }}</button>
          </template>
        </b-table>
        <user-form  v-if="step == 2" @order="makeOrder" @selectRegion="selectRegion"></user-form>
      </b-col>
      <b-col lg="3">
        <div class="order-summary">
          <p class="title text-center">{{ $t('cart.summary') }}</p>
          <b-row>
            <b-col class="text-start">{{ $t('cart.tItems') }}</b-col>
            <b-col class="text-end">
              {{  cartCount  }}
            </b-col>
          </b-row>
          <b-row>
            <b-col  class="text-start">{{ $t('cart.subtotal') }}</b-col>
            <b-col class="text-end">
              {{ cartTotalOffline }}<span class="currency">{{$t('egp')}}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-start">{{ $t('cart.tax') }}</b-col>
            <b-col class="text-end">
              0<span class="currency">{{$t('egp') }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-start">{{ $t('cart.fees') }}</b-col>
            <b-col class="text-end">
              {{ shipping_cost }}<span class="currency">{{$t('egp')}}</span>
            </b-col>
          </b-row>
          <b-row class="total-row">
            <b-col class="text-start">{{ $t('cart.total') }}</b-col>
            <b-col class="text-end">{{ cartTotalOffline + shipping_cost}}
              <span class="currency">{{$t('egp')}}</span>
            </b-col>
          </b-row>
          <b-row v-if="cartTotalOffline"><b-col lg="12">
            <b-button
                v-if="step == 1"
                class="curva-granola-btn small-rounded-btn" block
                @click="step = 2"
            >
              {{ $t('cart.step1') }}
            </b-button>
          </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <p v-else class="mb-4 text-center"> {{ $t('empty') }}</p>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import userForm from '@/modules/cart/views/userForm.vue'
import productsServices from '@/modules/all-products/services/products.services'
import { core } from '@/config/pluginInit'
export default {
  data () {
    return {
      shipping_cost: 0,
      step: 1,
      offlineCart: JSON.parse(localStorage.getItem('cart')) || [],
      tableHeader: [
        { key: 'productName', label: this.$t('cart.name'), class: 'px-3 text-initial' },
        { key: 'size', label: this.$t('cart.size'), class: 'px-3 text-initial' },
        { key: 'color', label: this.$t('productDetails.color-only'), class: 'px-3 text-initial' },
        { key: 'unitPrice', label: this.$t('cart.unitPrice'), class: 'px-3 text-initial' },
        { key: 'quantity', label: this.$t('cart.quantity'), class: 'px-3 text-initial' },
        { key: 'subtotal', label: this.$t('cart.subtotal'), class: 'px-3 text-initial' },
        { key: 'action', label: this.$t('cart.action'), class: 'px-3 text-initial' }
      ]
    }
  },
  components: {
    userForm
  },
  computed: {
    ...mapGetters(['cartItems', 'cartCount', 'cartTotalOffline'])
  },
  methods: {
    selectRegion (data) {
      this.shipping_cost = data.shipping_cost
    },
    makeOrder (data) {
      productsServices.guestOrder({ ...data, products: this.offlineCart.map(({ barcode, quantity }) => ({ barcode, quantity })) }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'invoices', params: { client_id: res.data.data.client_id, order_id: res.data.data.id } })
        this.$store.dispatch('clearCartOffline')
      })
    },
    changeQuantity (barcode, quantity) {
      this.$store.dispatch('changeCount', { barcode, quantity })
    },
    deleteFromCart (barcode) {
      this.$store.dispatch('removeFromCartOffline', barcode)
    }
  }

}
</script>

<style scoped>

</style>
